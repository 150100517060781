import fontColorContrast from 'font-color-contrast';
import ColorContrastChecker from 'color-contrast-checker';
import { useEffect, useState } from 'react';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';

var ccc = new ColorContrastChecker();

export const useGetColorTest = (backColor) => {
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [colorText, setColorText] = useState(null);

  const { accentColor } = GetBrandSettingFromRecoil();

  useEffect(() => {
    if (backColor) {
      setBackgroundColor(backColor);
    } else {
      setBackgroundColor(accentColor);
    }
  }, [backColor, accentColor]);

  useEffect(() => {
    if (ccc.isLevelAA(backgroundColor, accentColor, 24)) {
      setColorText(accentColor);
    } else {
      setColorText(fontColorContrast(accentColor));
    }
  }, [accentColor, backgroundColor]);

  return colorText;
};
