import Icon from '@ant-design/icons';

const Menu = (props) => (
  <svg
    version="1.1"
    viewBox="0 0 18.855469 15.095703"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <path
      transform="translate(-522.929 -5.129)"
      d="M523.93 5.129a1 1 0 0 0-1.001 1 1 1 0 0 0 1.002 1h16.853a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm0 6.549a1 1 0 0 0-1.001 1 1 1 0 0 0 1.002 1h16.853a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm-.001 6.547a1 1 0 0 0-1 1 1 1 0 0 0 1 1h16.857a1 1 0 0 0 .998-1 1 1 0 0 0-.998-1z"
    />
  </svg>
);

export const MenuIcon = (props) => <Icon component={Menu} {...props} />;

const ShowSidebar = () => (
  <svg
    version="1.1"
    viewBox="0 0 18.856 17.231"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g transform="translate(-481.829 -45.494)">
      <path d="M499.452 45.494a1.23 1.23 0 01.871 2.102l-6.513 6.513 6.513 6.514a1.23 1.23 0 11-1.74 1.742l-7.373-7.37a1.25 1.25 0 010-1.768l7.373-7.372c.23-.23.543-.36.87-.36zm-9.017 0a1.23 1.23 0 01.87 2.102l-6.513 6.513 6.514 6.514a1.23 1.23 0 11-1.74 1.742l-7.371-7.37a1.25 1.25 0 010-1.768l7.37-7.372c.231-.23.544-.36.87-.36z"></path>
    </g>
  </svg>
);

export const ShowSidebarIcon = (props) => (
  <Icon component={ShowSidebar} {...props} />
);

const HideSidebar = () => (
  <svg
    version="1.1"
    viewBox="0 0 18.856 17.231"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g transform="translate(-481.829 -5.494)">
      <path d="M483.061 5.494a1.23 1.23 0 00-.871 2.102l6.514 6.513-6.514 6.514a1.23 1.23 0 101.74 1.742l7.373-7.37a1.25 1.25 0 000-1.768l-7.373-7.372a1.23 1.23 0 00-.869-.36zm9.018 0a1.23 1.23 0 00-.872 2.102l6.514 6.513-6.514 6.514a1.23 1.23 0 101.74 1.742l7.372-7.37a1.25 1.25 0 000-1.768l-7.371-7.372a1.23 1.23 0 00-.87-.36z"></path>
    </g>
  </svg>
);

export const HideSidebarIcon = (props) => (
  <Icon component={HideSidebar} {...props} />
);

const Broadcast = ({ color, size }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={size || '2.5em'}
    height={size || '2.5em'}
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={color || '#ffffff'}
      stroke="none"
    >
      <path
        d="M810 4403 c-36 -7 -189 -167 -296 -308 -268 -356 -430 -755 -496
-1220 -17 -118 -17 -512 0 -630 53 -372 182 -737 362 -1025 144 -231 372 -492
440 -504 97 -17 181 52 180 151 -1 49 -11 66 -96 160 -165 183 -271 337 -374
545 -305 616 -305 1360 0 1976 103 208 209 362 374 545 86 95 95 111 96 162 1
98 -89 168 -190 148z"
      />
      <path
        d="M4201 4388 c-52 -27 -81 -74 -81 -131 0 -50 10 -68 89 -156 172 -192
281 -351 382 -556 303 -620 303 -1350 0 -1970 -101 -205 -210 -364 -382 -556
-78 -88 -89 -106 -89 -154 0 -73 39 -125 111 -145 67 -20 97 -5 196 97 356
363 596 871 675 1428 17 118 17 512 0 630 -53 372 -182 737 -362 1025 -108
173 -312 425 -389 479 -46 33 -99 36 -150 9z"
      />
      <path
        d="M1205 3962 c-16 -11 -57 -47 -89 -83 -286 -308 -463 -703 -506 -1128
-56 -557 139 -1128 524 -1529 61 -64 92 -82 143 -82 84 0 147 64 147 151 0 52
6 42 -128 200 -218 258 -350 562 -385 891 -51 469 94 916 417 1287 108 122
118 153 80 228 -38 77 -133 107 -203 65z"
      />
      <path
        d="M3770 3962 c-23 -12 -43 -35 -58 -65 -37 -72 -25 -111 55 -200 233
-258 369 -531 430 -862 23 -124 23 -426 0 -550 -61 -331 -197 -604 -430 -862
-76 -83 -89 -123 -61 -191 19 -50 60 -82 114 -89 79 -11 119 17 248 172 249
297 402 664 442 1054 56 557 -139 1128 -524 1529 -28 30 -63 60 -78 68 -37 19
-96 18 -138 -4z"
      />
      <path
        d="M1645 3546 c-73 -32 -239 -247 -314 -407 -93 -197 -125 -348 -125
-579 0 -231 32 -382 125 -579 83 -178 252 -389 330 -412 77 -24 170 30 185
107 11 62 -3 100 -67 172 -123 138 -208 296 -252 471 -30 123 -30 359 0 482
44 175 129 333 252 471 63 71 79 111 67 169 -9 47 -41 85 -86 103 -48 19 -75
20 -115 2z"
      />
      <path
        d="M3355 3541 c-44 -20 -72 -54 -81 -101 -12 -57 4 -97 67 -168 123
-138 208 -296 252 -471 30 -123 30 -359 0 -482 -44 -173 -129 -334 -252 -471
-63 -71 -79 -111 -67 -169 9 -48 42 -86 91 -104 66 -26 106 -14 172 48 167
157 309 427 360 684 24 121 24 384 0 505 -21 102 -76 266 -119 352 -48 96
-131 216 -205 296 -93 101 -138 118 -218 81z"
      />
      <path
        d="M2406 3304 c-150 -33 -274 -101 -381 -209 -156 -156 -235 -360 -222
-575 8 -118 27 -192 77 -295 96 -195 278 -344 490 -402 90 -24 290 -24 380 1
265 72 475 282 547 546 24 90 24 290 0 380 -72 262 -283 475 -542 545 -87 24
-264 28 -349 9z m245 -294 c84 -16 171 -65 237 -134 105 -109 148 -245 124
-388 -17 -98 -54 -171 -124 -244 -138 -144 -350 -183 -526 -98 -78 38 -172
130 -210 206 -172 342 122 728 499 658z"
      />
    </g>
  </svg>
);

export const BroadcastIcon = (props) => (
  <Icon
    style={{ marginRight: '1em' }}
    component={() => Broadcast({ color: props?.color, size: props?.size })}
  />
);
