import React from 'react';
import { GetBrandSettingFromRecoil } from '../recoil/brandsetting';
import DarkTheme from './dark';

const LightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (!col) return null;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

const Theme = () => {
  const { accentColor, pageTheme } = GetBrandSettingFromRecoil();

  const primaryColor = accentColor;
  const primaryColorLighten = LightenDarkenColor(accentColor, 20);
  const primaryColorDarken = LightenDarkenColor(accentColor, -20);

  return (
    <>
      <style global jsx>{`
        .countdown {
          line-height: 0.9 !important;
        }
        .ant-btn-primary {
          background: ${primaryColor};
          border-color: ${primaryColor};
        }
        .ant-btn-primary:hover,
        .ant-btn-primary:focus {
          background: ${primaryColorLighten};
          border-color: ${primaryColorLighten};
        }
        .ant-btn-primary:active {
          background: ${primaryColorDarken};
          border-color: ${primaryColorDarken};
        }
        .ant-btn-link {
          color: ${primaryColor};
        }
        .ant-btn-link:hover,
        .ant-btn-link:focus {
          color: ${primaryColorLighten};
        }
        .ant-btn-link:active {
          color: ${primaryColorDarken};
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: ${primaryColor} !important;
        }
        .ant-tabs-tab:hover {
          color: ${primaryColorLighten} !important;
        }
        .ant-tabs-ink-bar {
          background: ${primaryColor} !important;
        }
        .ant-menu-horizontal > .ant-menu-item-active,
        .ant-menu-horizontal > .ant-menu-submenu-active,
        .ant-menu-horizontal > .ant-menu-item-open,
        .ant-menu-horizontal > .ant-menu-submenu-open,
        .ant-menu-horizontal > .ant-menu-item-selected,
        .ant-menu-horizontal > .ant-menu-submenu-selected {
          color: ${primaryColor} !important;
        }
        .ant-menu-horizontal > .ant-menu-item:hover,
        .ant-menu-horizontal > .ant-menu-submenu:hover {
          color: ${primaryColor} !important;
        }
        .ant-menu-horizontal > .ant-menu-item a:hover,
        .ant-menu-horizontal > .ant-menu-submenu a:hover,
        .ant-menu-item a:hover {
          color: ${primaryColor} !important;
        }
        .ant-menu-horizontal > .ant-menu-item:hover::after,
        .ant-menu-horizontal > .ant-menu-submenu:hover::after {
          border-color: ${primaryColor} !important;
        }
        .ant-menu-submenu:hover
          > .ant-menu-submenu-title
          > .ant-menu-submenu-expand-icon,
        .ant-menu-submenu:hover
          > .ant-menu-submenu-title
          > .ant-menu-submenu-arrow,
        .ant-menu-submenu:hover > .ant-menu-title-content,
        .ant-menu-light .ant-menu-submenu-title:hover {
          color: ${primaryColor};
        }
        a,
        .ant-breadcrumb-link a {
          color: ${primaryColor} !important;
        }
        a:hover,
        .ant-breadcrumb-link a:hover {
          color: ${primaryColorDarken} !important;
        }
        .ant-breadcrumb-link {
          color: ${pageTheme === 'dark'
            ? primaryColorLighten
            : 'auto'} !important;
        }
        .auth0-lock-tabs > li > a {
          color: rgba(92, 102, 111, 0.6) !important;
        }
        .glider-dot.active {
          background: ${primaryColor};
        }
        .glider-dot:focus,
        .glider-dot:hover {
          background: ${primaryColorLighten};
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${primaryColorLighten};
          border-color: ${primaryColorLighten};
        }
      `}</style>
      {pageTheme === 'dark' && <DarkTheme />}
    </>
  );
};

export default Theme;
